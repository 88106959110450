<template>
  <AbstractDataTable
    :headers="tableHeaders"
    :items="[sport24teamSeason]"
    :current-page="1"
    :on-page-change="() => {}"
    :show-actions="false"
    :show-pagination="false"
    table-css-class="sport-season-table"
    >
    <template #tableBody="{ item }">
      <TitleColumn
        :title-header="tableHeaders[0].name"
        :title-value="$route.params.id"
        subtitle
      />
      <TitleColumn
        :title-header="tableHeaders[1].name"
        :title-value="item.nation"
      />
      <TitleColumn
        :title-header="tableHeaders[2].name"
        :title-value="item.sport"
      />
      <TitleColumn
        :title-header="tableHeaders[3].name"
        :title-value="item.season"
      />
      <TitleColumn
        :title-header="tableHeaders[4].name"
        :title-value="item.competition"
      />
      <TitleColumn
        :title-header="tableHeaders[5].name"
        :title-value="item.year"
      />
    </template>
  </AbstractDataTable>
</template>

<script>
import AbstractDataTable from '@/components/table/AbstractDataTable'
import TitleColumn from '@/components/table/columns/TitleColumn'

export default {
  name: 'SportTeamSeasonHeaderTable',
  props: {
    sport24teamSeason: {
      type: Object,
      required: true
    }
  },
  components: {
    TitleColumn,
    AbstractDataTable
  },
  data () {
    return {
      tableHeaders: [
        { name: this.$t('sport24.list.id') },
        { name: this.$t('sport24.list.nationName') },
        { name: this.$t('sport24.list.fortunaSportName') },
        { name: this.$t('sport24.list.fortunaCatName') },
        { name: this.$t('sport24.list.competitionName') },
        { name: this.$t('sport24.list.year') }
      ]
    }
  }
}
</script>
<style lang="scss">
  .sport-season-table {
    &__thead,
    &__tr {
      @include bp(12) {
        grid-template-columns: rem(50px) rem(130px) rem(130px) rem(130px) rem(130px) rem(130px) max-content;
      }
      @include bp(14) {
        grid-template-columns: rem(50px) rem(160px) rem(160px) rem(160px) rem(160px) rem(160px) rem(138px);
      }
    }
  }
</style>
