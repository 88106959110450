<template>
  <AbstractDataTable
    :headers="getTableHeaders(seasonGroup)"
    :items="seasonGroup.teams"
    :current-page="1"
    :on-page-change="() => {}"
    :show-actions="false"
    :show-pagination="false"
    table-css-class="sport-season-detail-table"
  >
    <template #tableBody="{ item }">
      <TitleColumn
        :title-header="getTableHeaders(seasonGroup)[0].name"
        :title-value="item.rank"
        subtitle
      />
      <TitleColumn
        :title-header="getTableHeaders(seasonGroup)[1].name"
        :title-value="item.team"
      />
      <TitleColumn
        :title-header="getTableHeaders(seasonGroup)[2].name"
        :title-value="item.overall.games"
      />
      <TitleColumn
        :title-header="getTableHeaders(seasonGroup)[3].name"
        :title-value="item.overall.win"
      />
      <TitleColumn
        :title-header="getTableHeaders(seasonGroup)[4].name"
        :title-value="item.overall.winOt"
      />
      <TitleColumn
        :title-header="getTableHeaders(seasonGroup)[5].name"
        :title-value="item.overall.tie"
      />
      <TitleColumn
        :title-header="getTableHeaders(seasonGroup)[6].name"
        :title-value="item.overall.lost"
      />
      <TitleColumn
        :title-header="getTableHeaders(seasonGroup)[7].name"
        :title-value="item.overall.lostOt"
      />
      <TitleColumn
        :title-header="getTableHeaders(seasonGroup)[8].name"
        :title-value="item.overall.points"
      />
    </template>
  </AbstractDataTable>
</template>

<script>
import AbstractDataTable from '@/components/table/AbstractDataTable'
import TitleColumn from '@/components/table/columns/TitleColumn'

export default {
  name: 'SportTeamSeasonDetailTable',
  props: {
    seasonGroup: {
      type: Object,
      required: true
    }
  },
  components: {
    TitleColumn,
    AbstractDataTable
  },
  methods: {
    getTableHeaders (seasonGroup) {
      return [
        { name: '' },
        { name: seasonGroup.seasonGroup },
        { name: this.$t('sport24.games') },
        { name: this.$t('sport24.won') },
        { name: this.$t('sport24.won_2') },
        { name: this.$t('sport24.drawn') },
        { name: this.$t('sport24.lost') },
        { name: this.$t('sport24.lost_2') },
        { name: this.$t('sport24.points') }
      ]
    }
  }
}
</script>

<style lang="scss">
.sport-season-detail-table {
  &__thead,
  &__tr {
    @include bp(12) {
      grid-template-columns: rem(50px) rem(130px) rem(70px) rem(70px) rem(70px) rem(70px) rem(70px) rem(70px) rem(70px);
    }
    @include bp(14) {
      grid-template-columns: rem(50px) rem(160px) rem(100px) rem(100px) rem(100px) rem(100px) rem(100px) rem(100px) rem(100px);
    }
  }
}
</style>
