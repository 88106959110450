<template>
  <ModuleForm
    :module-name="$t('moduleForm.sport24')"
    :module="sport24teamSeason"
    :show-header-info="false"
  >
    <template #header-buttons>
      <ModuleFormButton
        icon="close"
        @click="$router.push('/sport24')"
      />
    </template>
    <template #form-content>
      <SportTeamSeasonHeaderTable
        :sport24team-season="sport24teamSeason"
        class="season-header"
      />
      <div
        v-for="(seasonGroup, index) in sport24teamSeason.seasonGroups"
        :key="`item-${index}`"
        class="season-group"
      >
        <SportTeamSeasonDetailTable :season-group="seasonGroup" />
      </div>
    </template>
  </ModuleForm>
</template>

<script>
import SportTeamSeasonModel from '@/model/SportTeamSeasonModel.js'
import SportTeamSeasonHeaderTable from '@/components/table/SportTeamSeasonHeaderTable'
import SportTeamSeasonDetailTable from '@/components/table/SportTeamSeasonDetailTable'
import ModuleForm from '@/components/shared/ModuleForm'
import ModuleFormButton from '@/components/shared/ModuleFormButton'

export default {
  name: 'SportTeamSeasonView',
  data () {
    return {
      sport24teamSeason: SportTeamSeasonModel,
      error: null
    }
  },
  components: {
    ModuleFormButton,
    ModuleForm,
    SportTeamSeasonDetailTable,
    SportTeamSeasonHeaderTable
  },
  methods: {
    getSport24teamSeason () {
      this.$store.dispatch('sport24teamSeason/fetch', this.$route.params.id)
        .then(() => {
          this.sport24teamSeason = this.$store.getters['sport24teamSeason/detail']
        })
    }
  },
  mounted () {
    this.getSport24teamSeason()
  }
}
</script>

<style scoped lang="scss">
.season-header {
  margin-bottom: 2rem;
}
.season-group {
  margin-bottom: 1rem;
}
</style>
